import React from 'react';
import { useSelector } from 'react-redux';
import * as contactsSelector from '../../../state/contacts/selectors';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import momentDurationFormatSetup from "moment-duration-format";
import { useEffect } from 'react';
import Api from '../../../state/Api';
import { Bar } from 'react-chartjs-2';

momentDurationFormatSetup(moment);

  const columns = [
    { id: 'name', label: 'Agente', minWidth: 170 },
    { id: 'code', label: 'Duracion', minWidth: 150 },
  ];
  
  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    tableContainer: {
      height : '100%',
      maxHeight : '300px'
    },
    chip : {
        marginBottom : '10px'
    },
    paperStyle : {
        width : '50%',
        textAlign :'center',
        marginBottom : '10px'
    },
    destacadoNumber : {
       color : '#42a5f5'
    }
  });

const Detalles = ( props ) => {

    const [ meliOrders, setMeliOrders ] = React.useState({})
    const [ meliShipments, setMeliShipments ] = React.useState(null)


    useEffect( () => {
      const controller = new AbortController();

      const getData =  async () => {
        let dataOrders = await Api.req.get(`https://omni.tevecompras.com/meli/orders/${props.contact.lastMsgReference}`, {
          signal: controller.signal
        })
        setMeliOrders(dataOrders.data); 
        let dataShipments = await Api.req.get(`https://omni.tevecompras.com/meli/shipments/${dataOrders.data.shipping.id}`, {
          signal: controller.signal
        })
        setMeliShipments(dataShipments.data)
      }
  
      getData()

      return () => controller.abort();

    }, [props.contact])

    const classes = useStyles();

    const allMessages = useSelector(contactsSelector.getMessages(props.contact.id));
    const allMessagesSent = useSelector(contactsSelector.getMessagesSent(props.contact.id));
    const averageTime = allMessages.length > 0 ? Math.round(allMessages[0].averageAttentionTime):null;
    const duracion = moment.duration(averageTime, "seconds").format("hh:mm:ss", { trim: false });

    const visualizeDuration = (time) => {
        return moment.duration(time, "seconds").format("hh:mm:ss", { trim: false });
    }

    const getMeliShipments = (shipmentData) => {
      if (shipmentData){
        let addressData =  shipmentData.receiver_address;
        return <>
                <strong>Tipo logística :</strong> {shipmentData.logistic_type } <br />
                <strong>Estado :</strong> {shipmentData.status } <br/>
                <strong>Dirección :</strong>  {addressData.street_name + ' ' + addressData.street_number + ' - ' + addressData.zip_code + ' - ' + addressData.city.name + ' - ' + addressData.state.name } 
              </> 
      }
    }

    return (
        <div className={classes.container}>

            {meliOrders.order_items ?  
            <div>
                <strong> Nombre </strong> :  {meliOrders.buyer.first_name + ' ' + meliOrders.buyer.last_name }  <br/> 
                <strong> Fecha </strong> : {moment(meliOrders.date_created).calendar() }  <br /> <br />

                <strong> Datos de la orden </strong> <br />
                <strong> Id :</strong>  { meliOrders.order_items[0].item.id } <br />
                <strong> Nombre :</strong> {meliOrders.order_items[0].item.title}<br />
                <strong> Precio por unidad :</strong> {meliOrders.order_items[0].item.unit_price }<br />
                <strong> Id de lista :</strong> {meliOrders.order_items[0].item.listing_type_id }<br />
                <br />

               
          </div> : ''}

        {getMeliShipments(meliShipments) } 
        
        </div>
    )
};

export default React.memo(Detalles);