import React,{ useState } from "react";
import { Button } from '@material-ui/core';
import { green, purple } from '@material-ui/core/colors';
import Api from "../../../state/Api";
import * as authSelectors from '../../../state/auth/selectors';
import * as contactsSelector from '../../../state/contacts/selectors'
import * as appActions from '../../../state/app/actions'
import * as contactsActions from '../../../state/contacts/actions'
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from "@material-ui/core/MenuItem";
import Select from '@material-ui/core/Select';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BlockUi from 'react-block-ui';

const ChatOffline = (props) => {

    const me = useSelector(authSelectors.getMe(), shallowEqual);
    const dispatch = useDispatch();
    const allMessages = useSelector(contactsSelector.getMessages(props.contact.id));
    const [openDesbordeModal, setOpenDesbordeModal] = useState(false); 
    const [openWhatsapModal, setOpenWhatsapModal ] =  useState(false)
    const [templatesList, setTemplatesList] = useState([{id : 1 , descripcion : 'hola'}])
    const [selectedTemplate, setSelectedTemplate] = useState('')
    const isSuper = useSelector(authSelectors.isSuper(), shallowEqual);

    const enviarDesborde = () => {

        const data = {
            campaignid: 13,
            nombres: props.contact.Nombres,
            telefono: props.contact.callerid,
            email: props.contact.E_Mail,
            msg: allMessages[0].msg,
            messagereference: props.contact.lastMsgReference
        }

        Api.req.post(`https://omni.tevecompras.com/prospectos/agent/${me.username}/prospecto/${props.contact.id}/desborde`, data).then((response) => {
            dispatch(appActions.addToast('Se ha enviado el desborde'))
            dispatch(contactsActions.fetchMessages(props.contact, me.username));
            handleCloseDesbordeModal()
        }, (error) => {
            dispatch(appActions.addToast('Ha habido un error al subir el archivo : ' + error.response.data.msg, 'error'))
            //setEnviandoDatos(false)
        })
    }

    const enviarWhatsap = () => {

        let template = getTemplate(selectedTemplate);

        const data = {
            templateId: template.id,
            mtmTemplateName: template.name,
            callerid: props.contact.callerid,
            attachmentURL: null,
            source: "Omnichannel",
            usuario: me.username
        }


        var campos = template.parameters;
        for (var i = 0; i < campos.length; i++) {
            data["Param" + i] = props.contact[campos[i].paramModel];
        } 

        Api.req.post(`https://omni.tevecompras.com/prospectos/agent/${me.username}/prospecto/${props.contact.id}/mtm/create`, data).then((response) => {

            dispatch(appActions.addToast('Se ha enviado el whatsap'))
            dispatch(contactsActions.fetchMessages(props.contact, me.username));
            handleCloseWhatsapModal()
        })
    }
    
    const handleCloseDesbordeModal = () => {
        setOpenDesbordeModal(false)
    }

    const handleOpenDesborde = () => {
        setOpenDesbordeModal(true)
    }

    const handleCloseWhatsapModal = () => {
        setOpenWhatsapModal(false)
      
    }

    const handleOpenWhatsapModal = () => {
        Api.req.get(`https://omni.tevecompras.com/campaigns/${props.contact.campaignid}/templates/whatsapp`).then((response) => {
            setOpenWhatsapModal(true)
            console.log(response.data.data)
            setTemplatesList(response.data.data)
        })
    }


    const getReplaceParam = (toReplace) => {

        return props.contact[toReplace.paramModel];
    }

    const getTemplate = (id) => {
        return templatesList.find( template => template.id == id )
    }

    const replaceTemplate = (templateID) => {

        if (templateID) {

            let template = getTemplate(templateID);
            let textToReplace = template.body;
            let params = template.parameters;
            let hasParams = template.parameters.length > 0 ? true : false;
            //if (this.linkUrl) { params.push(this.linkUrl) }

            if (hasParams) {
                for (var i = 0; i < params.length; i++) {

                    let showParam = getReplaceParam(params[i])

                    textToReplace = textToReplace.replace("{{" + params[i].paramOrder + "}}", showParam);

                }
            }

            return textToReplace;
        }
    }

    const getWhatsapButton = () => {
        if ((props.messageDue && (props.contact.campaignid == 4 || props.contact.campaignid == 36) ) || !props.messageDue ){
            return (
                <Button variant="contained" onClick={handleOpenWhatsapModal} style={{ backgroundColor: green[500], color: 'white' }} >Enviar a Whatsapp</Button>
            )
        }
    }
    
    return(
        <>
            <Dialog
                open={openDesbordeModal}
                onClose={handleCloseDesbordeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        
            >
                <DialogTitle id="alert-dialog-title">
                    Enviar a Desborde
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       ¿ Esta seguro que desea enviar el contacto a desborde ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary"  onClick={handleCloseDesbordeModal}>NO</Button>
                    <Button color="primary" onClick={enviarDesborde} autoFocus>
                        SI
                    </Button>
                </DialogActions>
            </Dialog>

            
                <Dialog
                    open={openWhatsapModal}
                    onClose={handleCloseWhatsapModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">
                        Contactar Cliente
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Select
                                displayEmpty
                                id="template"
                                value={selectedTemplate}
                                onChange={(event) => setSelectedTemplate(event.target.value)}
                            >
                                <MenuItem value="">Seleccione</MenuItem>
                                {templatesList.map((option, index) => (
                                    <MenuItem key={index}  value={option.id}>
                                        {option.description}
                                    </MenuItem>
                                ))}
                            </Select>
                            <br /><br />
                            { replaceTemplate(selectedTemplate) }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={handleCloseWhatsapModal}>Cancelar</Button>
                        <Button disabled={!!!selectedTemplate} color="primary" onClick={enviarWhatsap} autoFocus>
                            Enviar
                        </Button>
                    </DialogActions>
                </Dialog> 
            { /*  !props.messageDue ? <Button variant="contained" onClick={handleOpenDesborde} color="primary">Enviar A Desborde</Button> : ''  */}
            {isSuper ? getWhatsapButton() : ''}
        </>
    )

}

export default ChatOffline;